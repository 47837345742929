import React, { useState } from 'react';
import SectionTitle from '../../components/SectionTitle'
import { TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import classnames from 'classnames';
import sImg1 from '../../images/story/1.jpg'
import sImg2 from '../../images/story/ask2.png'
import sImg3 from '../../images/story/3.jpg'
import sImg4 from '../../images/story/4.jpg'

import sIcon1 from '../../images/story/1.png'
import sIcon2 from '../../images/story/2.png'
import sIcon3 from '../../images/story/3.png'
import sIcon4 from '../../images/story/4.png'


import Shape from '../../images/story/shape.jpg'
import { Link } from '@material-ui/core';


const StorySection = (props) => {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }


    const Tabs = [
        {
            Id: '1',
            tabItem: 'Our Story',
        },
        {
            Id: '2',
            tabItem: 'Officially Dating',
        },
        {
            Id: '3',
            tabItem: 'Marriage Proposal',
        },
        {
            Id: '4',
            tabItem: 'Our Đám Hỏi',
        }
    ]

    const Story = [
        {
            Id: '1',
            sImg: sImg1,
            sIcon:sIcon1,
            Shape:Shape,
            sTitle:'Our Story',
            date:'',
            description:"Theresa and Trung met when they were kids in a youth program called Thiếu Nhi. A week before Trung's 7th birthday, Theresa and Trung's cousins had a girls-only hangout. So during Trung's 7th birthday, Trung closed his bedroom door and put a sign on the door that said “no girls allowed.” "
        },
        {
            Id: '2',
            sImg: sImg2,
            sIcon:sIcon2,
            Shape:Shape,
            sTitle:'Officially Dating',
            date:'Apr 26,2021',
            description:'Trung wanted to wait for the perfect time to ask Theresa to be his girlfriend. On the night of the Pink Moon and Wiley’s (Trung’s family dog) 1st birthday, we had dinner under the stars (the inspiration for our theme)!'
        },
        {
            Id: '3',
            sImg: sImg3,
            sIcon:sIcon3,
            Shape:Shape,
            sTitle:'Marriage Proposal',
            date:'July 28,2023',
            description:'Trung took Theresa to Mt. Baker Beach in Seattle, where they had first confessed. The beach was packed but Trung led Theresa onto the dock, surrounded by strangers and professed his love for her and promised her that they would be together forever. (It was a lot messier than this, check out the read more for details)'
        },
        {
            Id: '4',
            sImg: sImg4,
            sIcon:sIcon4,
            Shape:Shape,
            sTitle:'Our Đám Hỏi',
            date:'Sep 10,2023',
            description:'Lễ Đính Hôn, also known as Đám Hỏi is a Vietnamese engagement tea ceremony. This ceremony gave the families of both Trung and Theresa the chance to celebrate their engagement. Trung’s family came over and asked Theresa’s family for permission to Trung to take Theresa’s hand to be betrothed. This event kickstarts the countdown to Theresa and Trung’s wedding!'
        }
    ]



    return (

        <section className="wpo-story-section section-padding" id="story">
            <div className="container">
                <SectionTitle MainTitle={'Our love story'}/>
                <div className="row align-items-center justify-content-center">
                    <div className="col col-lg-12 col-12">
                        <div className="tab-area">
                            <div className="tablinks">
                                <Nav tabs className="service-thumbs-outer">
                                    {Tabs.map((tab, titem) => (
                                        <NavItem key={titem}>
                                            <NavLink
                                                className={`service-thumb ${classnames({ active: activeTab === tab.Id })}`}
                                                onClick={() => { toggle(tab.Id); }}
                                            >
                                                {tab.tabItem}
                                                <span className="number">{tab.tabNumber}</span>
                                            </NavLink>
                                        </NavItem>
                                    ))}
                                </Nav>
                            </div>
                            <TabContent activeTab={activeTab}>
                                {Story.map((story, Sitem) => (
                                    <TabPane tabId={story.Id} key={Sitem}>
                                        <div className="wpo-story-item">
                                            <div className="wpo-story-img">
                                                <img src={story.sImg} alt="" style={{
                                                    aspectRatio: 16/9,
                                                    objectFit: 'cover'
                                                }}/>
                                            </div>
                                            <div className="wpo-story-content">
                                                <div className="wpo-story-content-inner">
                                                    <span><img src={story.sIcon} alt=""/></span>
                                                    <h2>{story.sTitle}</h2>
                                                    <span>{story.date}</span>
                                                    <p>{story.description} <Link href={'#/blog/' + story.Id}>Read More..</Link></p>
                                                    {/* <div className="border-shape">
                                                        <img src={story.Shape} alt=""/>
                                                    </div> */}
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                ))}
                            </TabContent>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default StorySection;