// images
import sImg1 from  '../images/story/1.jpg'
import sImg2 from '../images/story/ask2.png'
import sImg3 from '../images/story/3.jpg'
import sImg4 from '../images/story/4.jpg'

import pandemic from "../images/story/pandemic.jpg"
import rubberducky from "../images/story/rubberducky.jpg"
import us2gether from "../images/story/us2gether.jpg"



const blogs = [
    {
        id: '1',
        title: 'Our Story',
        screens: sImg1,
        description: 'I think I love you too',
        author: 'Trung Peter Nguyen',
        create_at: 'Till now~~',
        blogSingleImg:sImg1, 
        blClass:'format-standard-image',
        article:`<img src="`+ us2gether +`" alt="Wedding" class="col-xl-6 col-lg-6 col-12 p-2" style="float: left;"><p>
        As kids, we didn't hang out as much together because Theresa hung out with my cousins: Teresa and Tracy. It was GIRLS ONLY; NO BOYS ALLOWED! Which, of course, I flipped the script on them when they were over for my 7th birthday. (Which, of course, the girls fought back. And we all got into a big argument.) I also remember small fragmented memories like going swimming at the community pool in Kent and being so loud that the people taking care of us made us play the quiet game. I tried to make some faces to make her laugh and hopefully lose, but Theresa was really good.</p>
     
    <p>After that, we really weren’t as close. Both of us were in Thiếu Nhi (the Vietnamese Eucharistic Youth Movement) together which kept us close enough where we still knew of each other. We didn’t interact as much because we were in different classes. Outside of our hangouts with Teresa and Tracy, we were in social circles just barely crossing each other throughout the years.</p>
    
    <p>But as the global pandemic began to shut the world down, our relationship began to blossom. As things went online, we hopped on Discord and kept each other company as we worked in the morning. When we got off work, we would talk with each other, share experiences like cooking/working out, and sharing screens to watch shows that we haven’t watched yet. They kind of gave a glimpse into who we are as people and helped us get to know each other better. Some of our favorite shows and movies are:
    </p>
    
    <h4 style="color:white;">Movie/Show Recomendations:</h4>
            <div class="col-12 movieDiv">
            <div class="moviePosters">
                <img src="https://onedrive.live.com/embed?resid=8E2E516E19319715%2147434&authkey=%21AKd4JsZrgkt2hIY&height=256" alt="Attack on Titan Poster" class="image">
                <div class="title">Attack on Titan</div>
                <div class="text">Best Story</div>
            </div>
    
            <div class="moviePosters">
                <img src="https://onedrive.live.com/embed?resid=8E2E516E19319715%2147440&authkey=%21ABZJUsaaqFqFGSU&height=256" alt="Dating Around Poster" class="image">
                <div class="title">Dating Around</div>
                <div class="text">Cinematography</div>
            </div>
    
            <div class="moviePosters">
                <img src="https://onedrive.live.com/embed?resid=8E2E516E19319715%2147435&authkey=%21ACjdYbUPG4ASEwc&height=256" alt="Spiderverse Poster" class="image">
                <div class="title">Spiderverse</div>
                <div class="text">Original Score</div>
            </div>
    
            <div class="moviePosters">
                <img src="https://onedrive.live.com/embed?resid=8E2E516E19319715%2147439&authkey=%21AH2cPYUkQ8-uxeE&height=256" alt="Klaus Poster" class="image">
                <div class="title">Klaus</div>
                <div class="text">Animated Film</div>
            </div>
    
            <div class="moviePosters">
                <img src="https://onedrive.live.com/embed?resid=8E2E516E19319715%2147441&authkey=%21AN_1Y-UlTJlYyFc&height=256" alt="Clannad Poster" class="image">
                <div class="title">Clannad</div>
                <div class="text">Anime</div>
            </div>
    
            <div class="moviePosters">
                <img src="https://onedrive.live.com/embed?resid=8E2E516E19319715%2147438&authkey=%21AIWy7_ZBLcwCij0&height=256" alt="Love is Blind Poster" class="image">
                <div class="title">Love is Blind - Japan</div>
                <div class="text">Production/Visuals</div>
            </div>
      </div>
      <h4 style="color:white;">God's Call</h4>
            <p>For me, one of my favorite things that we did was that we would pray together on call. Sometimes, we would do a rosary and sometimes some journaling time but at least prayer to end off the day. The year we entered the pandemic, I was the ngành trưởng of ngành thiếu, guiding a team of youth leaders working with 5th to 7th graders. I was going through a hard time and had actually told our Thiếu Nhi chapter's president at that time that I didn't think I would be able to handle the duties of my leadership position. He said that I should pray a Novena (Prayer for nine consecutive days) and pray on a decision. Theresa reached out to a lot of the people around me and rallied them to pray with me. Reflecting on events before and at the start of our relationship, I sensed God’s guiding hand. It was only with Theresa that I truly felt His push toward someone or something. I will forever be grateful for having her in my life.
            </p>
            <h4 style="color:white;">Did you know…</h4>
            <ul>
    <li>Because of the song “September” by Earth, Wind & Fire, Trung and Theresa reserved the date as soon as possible. They were scared that it would be taken because of the song’s popularity.</li><br/>
    <li>Trung is currently working for the City of Seattle, but he originally wanted to be a clown! Baby Trung wanted to make everyone happy.</li><br/>
    <li>Theresa is finishing up her grad program to become a school counselor. She wanted to be a pediatrician, but psychology was more interesting.</li><br/>
    <li>The two furry loves of their lives are named: Wiley and Luna (Trung’s family dogs).</li><br/>
    <li>Trung threw Theresa her first surprise birthday party. And then she surprised him for his birthday. And then he surprised her with the after-engagement party. Their friends are exhausted from surprise parties, so the couple have promised to calm down.</li><br/>
    <li>Trung used his coding skills to create a randomizer based on a list of movies/TV shows/anime they want to watch. They use this list like a database of things they’ve already watched, too.</li><br/>
    <li>Theresa has a vague memory of when they were kids: Trung and Teresa ran up and shared that they were all going to watch Narnia together in the movie theater. Theresa does not remember going to watch Narnia. Theresa does not know what happened that day after this conversation. Narnia also happens to be one of Peter’s favorite childhood novels.</li><br/>
</ul>

    
        `
    },
    {
        id: '2',
        title: 'Officially Dating',
        screens: sImg2,
        description: 'You make my life so much fun',
        author: 'Theresa Le',
        create_at: 'Apr 26,2021',
        blogSingleImg:sImg2, 
        blClass:'format-standard-image',
        article:`<img src="`+ pandemic +`" class="col-xl-6 col-lg-6 col-12 p-2" style="float: left;"/> 
        <p>The first year was a rollercoaster. We kept in touch throughout the pandemic, worked together and worked through things together. We became a constant for each other during this tumultuous time and this helped to bring us closer together.  </p>
        <p>In truth, it was so hard that I don’t think we would be together today if it wasn’t for the grace of God at the center of our relationship. I’m not a huge believer in astrology, zodiac, or numerology, but there were so many signs that pointed to this time period being a huge time of transition for me, personally. In a lot of ways, our lives became like a bag turned upside down. We were fighting to figure out what things we wanted to keep in our bags, what we could let go of, and what we wanted to add to a wishlist of things to include later on down the line.</p>
        <img src="`+ rubberducky +`" width="400" style="float: right;"/>
        <p>Naturally, we learned a lot about each other. Thanks to Discord, we were able to keep in contact while the rest of the world was physically separated. We weren’t comfortable progressing with an official relationship, initially, but we knew that we still had romantic feelings for each other. I expressed to Trung that I would like an “ask” to redefine our relationship. Trung agreed, adding that he also wanted to ask my parents if it was okay with them as well. (He also often teased that he would sing the “Rubber Ducky” song from Sesame Street as part of him asking to be his girlfriend.)</p>
        <p>In 2021, word had gone around online that an astrological phenomenon called the Pink Moon or Supermoon was going to occur. Being that the world was starting to open up again, we made plans to view the moon together. It also happened to land on Wiley’s (Peter’s family dog) first birthday! After a long day at work, we made plates of steak and my siblings slowly trickled over to Peter’s house to join us. Francis had gone outside with Peter to the backyard to set up for all of us to dine under the moon. Inside the house, Mary and I were exchanging videos we’d seen lately, waiting for the other two to let us know they were done.</p>
        <p>
        It felt like an hour before I made my way to the door to see what was taking so long. Peter and Francis met me at the door and told me that everything was ready. We all walked out to a string of lights swung between trees, a table set for two, and a song. Several voices overlapped - or maybe it would be more specific to describe as Peter’s voice duplicated over several times. And then I heard the words:
        </p>
        <blockquote>
            Theresa you’re the one<br/>
            You make my life so much fun
        </blockquote>
        
        <p>My hands flew to my mouth as I realized what was happening.</p> 
        
        <p>Under the Pink Moon (that was really bright, but not actually pink), Peter officially asked me to be his girlfriend.</p>
        
        <p>(And fun fact: that is the inspiration behind our theme.)</p>`
    },
    {
        id: '3',
        title: 'Marriage Proposal',
        screens: sImg3,
        description: 'Are you gonna propose to me today?',
        author: 'Theresa Le',
        create_at: 'July 28,2023',
        blogSingleImg:sImg3,
        blClass:'format-standard-image',
        article:`
        <iframe class="col-xl-6 col-lg-6 col-12 p-2" style="float: left; aspect-ratio:16/9;" src="https://www.youtube.com/embed/vZ5t_iX67XI?si=7NTQPx1ESrZeJ_Rg" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

        <p>Just in case you weren’t sure, we agreed to get married. At the start of July, Peter and his family came over to my house to ask for permission from both of our parents to progress in our relationship. We had always known we wanted to celebrate our engagement with a traditional Vietnamese tea ceremony. My parents wanted us to wait until this ceremony to officially declare ourselves as engaged to the world, after coming together in front of our ancestors and family. I didn’t wear my engagement ring regularly until after that moment. I’ll leave Peter to tell the rest of that story, though.</p>

        <p>If you know me, then you know I still wanted an “ask” outside of all our planning toward the dám hỏi. The months leading up to our dám hỏi came with a lot of big changes around the home. My parents love home improvement projects around the house, with one big project almost every summer since we’ve moved in. This summer was probably one of the most drastic transformations in a long time, with plans for our entire downstairs and front yard. During my free time from homework (I was taking summer quarter) and work, Peter and I would help my family.</p>
        
        <p>July 28, 2023 started out like any other day. Peter and I had plans to move around furniture to prep for the next project. We had planned to take Peter’s siblings shopping because his sister needed to buy a present for her friend and then I would end my day working on an essay for one of my classes. Around lunchtime, Peter told me he needed to go home. What? Wait. But that’s not what we had planned for our day? I started crying from all the stress trying to juggle all the to-dos. Peter sat me down and leveled himself in front of me, taking my hands in his. He asked me if I was okay with letting go of some of the plans we had for the day and following his lead instead. I nodded and immediately started rearranging my schedule around what he had mapped out for me: he would go home, do what he needed to do, I would have time to work on my essay during that time, then he’d come back and pick me up so that we could go shopping, and then come home to finish moving furniture. Okay, good deal!</p>
        
        <p>Things went smoothly from there. I was able to hammer out an outline and start on a draft for my essay and Peter picked me up as planned. We had taken his younger sister, Little Mary, and youngest brother, Lucas, to the mall and then to Target. The trip went for longer than it took for us to shop because Peter made frequent trips to the restroom (iykyk). By the time we left to take me home, it was close to 8:00PM. I was starting to get nervous because we had left a mess at my house and we needed to go back and help my parents move the rest of the furniture.</p>
        
        <p>Then Joseph, one of Peter’s brothers, called. “Can you get some screws from Lowe’s?”</p>
        
        <p>We were on Rainier, turning onto Seward Park. Peter wasn’t turning the car around, so I suggested, “Why don’t we go to the Lowe’s at the Landing? It’s just over there.”</p>
        
        <p>“No, I’m already going this way, so let’s go to the one by Franklin.”
        Ok? But that’s so far. That doesn’t make sense to me. “My dad should have a box of some screws. How many does he need?”
        </p>
        
        <p>“No, it’s ok, it won’t take that long, I promise.”</p>
        
        <p>The car had become silent because everyone seemed tired.<br>
        
        Lucas calmly, but urgently stated, “I need to go to the bathroom.”<br>
        
        I turned and asked, “Do you think you can hold it in until we get to Lowe’s?”<br>
        
        “No.”<br>
        
        “Is it #1 or #2?”<br>
        
        I don’t remember what his answer was because I was already thinking about where we could stop to use the restroom. We were coming up on the bend toward Mt. Baker Beach, so I told Lucas, “Okay, there’s a park nearby. We can stop and use the restroom there, but Mary has to go with you.”<br>
        
        “Ok.”<br>
        
        The beach was full of folks who had decided to picnic and fish on the dock, but Peter found a spot to park. Lucas and Mary hopped out of the car and I was surprised that Peter was making moves to get out of the car too.
        </p>
        
        <p>
            He made his way over to my side of the car. “Come on, let’s go on a walk!”<br/>
            <img src="https://onedrive.live.com/embed?resid=8E2E516E19319715%2147445&authkey=%21AEgSjySNDKzf0G0&width=256" width="256" height="auto" /><br/>
        
        “No, I’m really tired. Lucas isn’t going to take that long anyway, so you can walk but I’m going to nap.” I know that Mt. Baker Beach is a special place for us, but so much had already happened today that I just wanted to chill.<br/>
        
        Peter continued to coax me by intertwining my arm in his. He had grabbed the fanny pack Little Mary left behind in the car so I couldn’t use the excuse of “watching over valuables in the car.” Reluctantly, I exited the car to join Peter on his insistent stroll around our special place.<br/>
        
        He took my hand and guided me toward the Y docks, located across from the restrooms that Lucas and Little Mary had entered. I turned my head to glance at the bathrooms in case there was anything to be mindful of. To my surprise, I found Mary and Lucas standing, hands in pockets, mirroring my surprise.<br/>
        
        I shouted, “DIDN’T YOU NEED TO GO TO THE BATHROOM??”<br/>
        <img src="https://onedrive.live.com/embed?resid=8E2E516E19319715%2147447&authkey=%21AE_vytmgegyNh5A&width=256" width="256" height="auto" />
        </p>
        
        <p>
            “I DID ALREADY!” was Lucas’s shouted return.<br>
        
        Gears turning, I looked back toward Peter, who was waving to his friend and their partner.<br>
        
        <img src="https://onedrive.live.com/embed?resid=8E2E516E19319715%2147446&authkey=%21AKrjfzEH4_0ysWw&width=256" width="256" height="auto" /><br/>
        
        I’m someone who gets overwhelmed in crowded places and automatically minds my own path rather than scan faces in the surrounding area. I would not have noticed any other familiar faces on the docks that day if Peter did not say the following:<br>
        
        “Oh hey! What are you doing out here?”<br>
        
        “Oh, you know. Just going on a walk. Getting some air. It’s really nice weather today!”<br>
        
        <img src="https://onedrive.live.com/embed?resid=8E2E516E19319715%2147449&authkey=%21AM4Jacqn89Bgat8&width=256" width="256" height="auto" /><br/>
        
        His friend held up the camera attached to the strap around his neck and gave it a little shake.<br>
        
        <img src="https://onedrive.live.com/embed?resid=8E2E516E19319715%2147448&authkey=%21AAIqCTthpf18WDM&width=256" width="256" height="auto" /><br/>

        <img src="https://onedrive.live.com/embed?resid=8E2E516E19319715%2147469&authkey=%21AMH4-EW-LiQi2iM&height=400" class="p-2" width="auto" height="400" style="float: left;"/>“I guess I am.”<br/>
        
        I don’t remember what I said to include my voice in the small talk they exchanged, but I remember cackling while looking at all 3 of them. Peter and his friend exchanged goodbyes and we continued down to the end of the dock.<br>
        
        “Are you gonna propose to me today?”<br>
        
        
        
        Peter took both of my hands and we locked eyes. Among the other families enjoying the sun, fishing, and blasting their music, Peter passed me an earbud and played the Rubber Ducky song he played on the day he asked me to be his girlfriend. When the song ended, he reflected on how we don’t really know how long we’ve known each other because we don’t remember much from our days as kids. We’d come a long way since those days and he’d like to spend the rest of our lives learning, growing, and spending time together.
        On one knee, Peter asked me to marry him.<br>
        
        Naturally, I said yes.
        
        </p>
        <p>P.S. He threw us a surprise engagement party after this. I didn’t touch my essay until Monday. My parents had already finished moving the furniture by the time I got home.</p>
        `
    },
    {
        id: '4',
        title: 'Our Đám Hỏi',
        screens: sImg4,
        description: 'We are officially Engaged',
        author: 'Trung Peter Nguyen',
        create_at: 'Sep 10,2023',
        blogSingleImg:sImg4,
        blClass:'format-standard-image',
        article:`
        <div style="display: inline-block;" class="damHoiphoto col-3">
        <img src="https://onedrive.live.com/embed?resid=8E2E516E19319715%2147419&authkey=%21AD51EHvVJo6c_0E&height=660" alt="Wedding" style="width: 100%;">
    </div>
    
    <div style="display: inline-block;" class="damHoiphoto col-3">
        <img src="https://onedrive.live.com/embed?resid=8E2E516E19319715%2147433&authkey=%21AMF4LSxKXMAhVfc&height=660" alt="Rocks" style="width: 100%;">
        </div>
        <div style="display: inline-block;" class="damHoiphoto col-5">
        <img src="https://onedrive.live.com/embed?resid=8E2E516E19319715%2147444&authkey=%21ABLl2Ei7I0yuTPA&height=660" style="width: 100%;"/> 
        </div>
        <p>On that special day, we began by preparing thoughtful gifts. These included a whole roasted pig, Xôi Gấc, fresh fruits, trầu cau, tea, Viet pork sausage, and silk for Áo dài. Each gift had someone assigned to carry it, and one person from the girls’ side received it. We playfully referred to those on the guys’ side as ‘bung qua bois.’ As a personal touch, you gifted each of them a tie adorned with a picture of you both on the back.
        Trung, his family, and the ‘bung qua bois’ all gathered at Theresa’s house to drop off the gifts, where they were warmly welcomed by Theresa’s family. The tea ceremony followed, during which Trung and Theresa poured tea for the elders and offered a prayer. With the tea ceremony complete, Trung and Theresa became officially engaged. The festivities continued at Ding Feng, where both families celebrated with karaoke and delicious food.</p>
        <div style="display: flex; flex-wrap: wrap; padding: 0 4px;">
        
        
    </div>
        `
    },
];
export default blogs;