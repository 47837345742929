import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
import blogs from '../../api/blogs';
import { useParams } from 'react-router-dom'

const BlogSingle = (props) => {

    const { id } = useParams()

    const BlogDetails = blogs.find(item => item.id === id)
    useEffect(()=>{
        window.scrollTo(0, 0);
      },[])
    return (
        <section className="wpo-blog-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className={`col col-lg-12 col-12 ${props.blRight}`}>
                        <div className="wpo-blog-content">
                            <div className="post format-standard-image">
                                <div className="entry-media">
                                    <img src={BlogDetails.blogSingleImg} alt="" />
                                </div>
                                <div className="entry-meta">
                                    <ul>
                                        <li><i className="fi flaticon-user"></i> By <Link to="/blog-single/1">{BlogDetails.author}</Link> </li>
                                        <li><i className="fi flaticon-calendar"></i> {BlogDetails.create_at}</li>
                                    </ul>
                                </div>
                                <h2 style={{
                                    color: 'white'
                                }}>{BlogDetails.title}</h2>
                                <h3 style={{
                                    color: 'white'
                                }}>{BlogDetails.description}</h3>
                                <div dangerouslySetInnerHTML={{ __html: BlogDetails.article }} />
                            </div>

                            <div className="tag-share clearfix">
                                <div className="tag">
                                    <span style={{
                                        color: 'white'
                                    }}>Check Out: </span>
                                    <ul>
                                        {blogs.map((blog, Bitem) => (
                                            <li key={Bitem}><Link to={`/blog/${blog.id}`}>{blog.title}</Link></li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <BlogSidebar blLeft={props.blLeft}/> */}
                </div>
            </div>
        </section>
    )

}

export default BlogSingle;
